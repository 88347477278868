import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Heading from 'components/atoms/text/Heading/Heading';
import Rte from 'components/atoms/text/Rte/Rte';
import Image from 'components/atoms/media/Image/Image';
import Button from 'components/atoms/Button/Button';
import InviewAnimation from 'components/molecules/InviewAnimation/InviewAnimation';

import './TextImage.scss';

const TextImage = (props) => {
  const {
    heading,
    richText,
    button,
    image,
    reverseLayout,
  } = props;

  return (
    <div
      className={classNames('o-layout  o-layout--gutter-huge  o-layout--align-middle', {
        'o-layout--row-reverse': reverseLayout,
      })}
    >
      <div className="o-layout__cell  u-fraction--6/12@from-desk  u-mb">
        <InviewAnimation>
          <>
            <Heading {...heading} />

            <Rte {...richText} />

            { button ? (
              <Button
                {...button}
              />
            ) : null }
          </>
        </InviewAnimation>
      </div>
      <div className="o-layout__cell  u-fraction--6/12@from-desk  u-mb">
        <InviewAnimation delay>
          <Image {...image} />
        </InviewAnimation>
      </div>
    </div>
  );
};

TextImage.propTypes = {
  heading: PropTypes.objectOf(PropTypes.any),
  richText: PropTypes.objectOf(PropTypes.any),
  button: PropTypes.objectOf(PropTypes.any),
  image: PropTypes.objectOf(PropTypes.any),
  reverseLayout: PropTypes.bool,
};

TextImage.defaultProps = {
  heading: {},
  richText: {},
  button: null,
  image: null,
  reverseLayout: false,
};

export default TextImage;
