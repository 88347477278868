import React from 'react';
import PropTypes from 'prop-types';

import './Rte.scss';

const Rte = (props) => {
  const {
    richText,
    className,
  } = props;

  if (!richText) {
    return null;
  }

  return (
    <div
      className={`c-rte ${className}`}
      dangerouslySetInnerHTML={{ __html: richText }}
    />
  );
};

Rte.propTypes = {
  richText: PropTypes.string,
  className: PropTypes.string,
};

Rte.defaultProps = {
  richText: '',
  className: '',
};

export default Rte;
