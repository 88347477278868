import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';

import Heading from 'components/atoms/text/Heading/Heading';
import Button from 'components/atoms/Button/Button';
import Rte from 'components/atoms/text/Rte/Rte';
import Img from 'gatsby-image';

import './Hero.scss';

const Hero = (props) => {
  const {
    image,
    heading,
    button,
    richText,
    modifier,
  } = props;

  return (
    <StaticQuery
      query={graphql`
        query {
          wallImage: allFile {
            edges {
              node {
                relativePath
                childImageSharp {
                  fixed(height: 550, quality: 90) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
          lapImage: allFile {
            edges {
              node {
                relativePath
                childImageSharp {
                  fixed(height: 550, quality: 90, cropFocus: CENTER) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const wallImageSrc = data.wallImage.edges.find(
          (n) => n.node.relativePath.includes(image.src),
        );

        const lapImageSrc = data.lapImage.edges.find(
          (n) => n.node.relativePath.includes(image.src),
        );
        if (!image.src) { return null; }

        const sources = [
          lapImageSrc.node.childImageSharp.fixed,
          {
            ...wallImageSrc.node.childImageSharp.fixed,
            media: '(min-width: 768px)',
          },
        ];

        return (
          <section
            className={classNames('c-hero', {
              'c-hero--in-page': modifier === 'inPage',
            })}
          >
            <div className="c-hero__inner">
              <div className="c-hero__content">
                <Heading
                  {...heading}
                />

                <Rte
                  {...richText}
                />

                <Button
                  {...button}
                />
              </div>
              <div className="c-hero__image">
                <Img
                  fixed={sources}
                  objectFit="cover"
                  loading="auto"
                  objectPosition="50% 50%"
                  style={{
                    position: 'static',
                  }}
                />
              </div>
            </div>
          </section>
        );
      }}
    />
  );
};

Hero.propTypes = {
  image: PropTypes.objectOf(PropTypes.any),
  heading: PropTypes.objectOf(PropTypes.any),
  button: PropTypes.objectOf(PropTypes.any),
  richText: PropTypes.objectOf(PropTypes.any),
  modifier: PropTypes.string,
};

Hero.defaultProps = {
  image: {},
  heading: {},
  button: {},
  richText: {},
  modifier: '',
};

export default Hero;
