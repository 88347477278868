import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Image = ({
  src,
  className,
  objectFit,
  loading,
  style,
  alt,
}) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              publicURL
              name
              extension
              childImageSharp {
                fluid(quality: 90, maxWidth: 1600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.images.edges.find((n) => n.node.relativePath.includes(src));
      if (!src) { return null; }
      // Add SVG support
      if (!image.node.childImageSharp && image.node.extension === 'svg') {
        return <img src={image.node.publicURL} alt={alt} className={className} />;
      }

      const imageObject = image.node.childImageSharp.fluid;

      return (
        <Img
          alt={alt}
          objectFit={objectFit}
          fadein={false}
          durationFadeIn={500}
          style={style}
          fluid={imageObject}
          className={className}
          loading={loading}
        />
      );
    }}
  />
);

Image.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  objectFit: PropTypes.string,
  loading: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  alt: PropTypes.string,

};
Image.defaultProps = {
  src: '',
  className: '',
  objectFit: '',
  loading: 'lazy',
  style: {},
  alt: '',
};

export default Image;
