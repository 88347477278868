import React from 'react';
import PropTypes from 'prop-types';

import Theme from 'components/atoms/objects/Theme/Theme';
import Layer from 'components/atoms/objects/Layer/Layer';
import Retain from 'components/atoms/objects/Retain/Retain';
import TextImage from 'components/molecules/TextImage/TextImage';

const TextImageBlock = (props) => {
  const {
    data,
    themeColor,
  } = props;

  return (
    <Theme color={themeColor}>
      <Layer size="large" id={data.id}>
        <Retain size="wall">
          <TextImage {...data} />
        </Retain>
      </Layer>
    </Theme>
  );
};

TextImageBlock.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  themeColor: PropTypes.string,
};

TextImageBlock.defaultProps = {
  data: null,
  themeColor: '',
};

export default TextImageBlock;
