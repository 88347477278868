import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import './InviewAnimation.scss';

const InviewAnimation = (props) => {
  const {
    children,
    delay,
  } = props;

  const [isInView, setIsInView] = useState(false);

  const inViewRef = useRef(null);

  // SeekTo seconds passed in context state
  useEffect(() => {
    // Polyfill for IE
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line global-require
      require('intersection-observer');
    }

    const options = {
      root: null,
      threshold: 0.05,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting || entry.intersectionRatio > 0) {
          setIsInView(true);
        }
      });
    }, options);

    observer.observe(inViewRef.current);
  }, []);

  return (
    <div
      ref={inViewRef}
    >
      <div
        className={classNames('c-inview', {
          'is-inview': isInView,
          'c-inview--delay': delay,
        })}
      >
        {children}
      </div>
    </div>
  );
};

InviewAnimation.propTypes = {
  children: PropTypes.node.isRequired,
  delay: PropTypes.bool,
};

InviewAnimation.defaultProps = {
  delay: false,
};

export default InviewAnimation;
